

@charset "utf-8";


@import "variables.scss";


// ===============================================

// -----------------------------------------------
// Base Variables - import from Bootstrap
// -----------------------------------------------


@import "fonts";
@import "bootstrap-variables";
// via jekyll-bootstrap-sass:
@import "bootstrap-custom";


.padding-0 { padding: 0px; }


html, body { height: 100%; }


@mixin type-otf-lnum() {
    -moz-font-feature-settings: "lnum=1";
    -moz-font-feature-settings: "lnum";
    -ms-font-feature-settings: "lnum";
    -webkit-font-feature-settings: "lnum";
    font-feature-settings: "lnum";

    font-variant-numeric: lining-nums;
};

@mixin type-otf-swash() {
    -moz-font-feature-settings: "swsh=1,cswh=1";
    -moz-font-feature-settings: "swsh", "cswh";
    -ms-font-feature-settings: "swsh", "cswh";
    -webkit-font-feature-settings: "swsh", "cswh";
    font-feature-settings: "swsh", "cswh";
};

.type-lnum {
    @include type-otf-lnum();
}

.type-swash {
    @include type-otf-swash();
}





// general font settings - ligatures
p, li, h1, h2, h3, h4, h5, h6 {
    -moz-font-feature-settings: "calt=1, liga=1, clig=1, kern=1, onum=1";
    -moz-font-feature-settings: "calt", "liga", "clig", "kern", "onum";
    -ms-font-feature-settings: "calt", "liga", "clig", "kern", "onum";
    -webkit-font-feature-settings: "calt", "liga", "clig", "kern", "onum";
    font-feature-settings: "calt", "liga", "clig", "kern", "onum";

    font-variant-ligatures: common-ligatures discretionary-ligatures contextual;

    font-variant-numeric: oldstyle-nums;
}

// header styles
//
// common header styles
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    @include type-otf-swash();
}
// specific styles:
h3 {
    font-style: italic;
    //font-weight: bold;
}


// smaller font sizes for smaller screens
@media(max-width: $screen-md-min) {
    h1, .h1 { font-size: $phf-small-screen-header-font-size-factor*$font-size-h1; }
    h2, .h2 { font-size: $phf-small-screen-header-font-size-factor*$font-size-h2; }
    h3, .h3 { font-size: $phf-small-screen-header-font-size-factor*$font-size-h3; }
    h4, .h4 { font-size: $phf-small-screen-header-font-size-factor*$font-size-h4; }
    h5, .h5 { font-size: $phf-small-screen-header-font-size-factor*$font-size-h5; }
    h6, .h6 { font-size: $phf-small-screen-header-font-size-factor*$font-size-h6; }
}




blockquote {
    padding: 0.5em 2em;
    font-size: inherit;
    border: 0px none;
}
@media(max-width: 10*2em) {
    // correct for small screens
    blockquote {
        padding-left: 4%;
        padding-right: 4%;
    }
}




a {
    text-shadow: 3px 2px 0.3em rgba(0,0,0,0.1);
}
a:hover {
    text-shadow: 3px 2px 0.3em rgba(0,0,0,0.2);
}

.sidebar-nav {
    a:hover {
        text-shadow: 3px 2px 0.3em rgba($navbar-default-link-hover-color,0.3);
    }
}

.sidebar-nav {

    padding: 0px;

    .navbar {
        margin-bottom: 0pt;
    }

    .navbar-header {
        float: none;
        display: block;
    }
    .navbar-brand {
        float: none;
        display: block;
        height: auto;
    }
    .navbar .navbar-collapse {
        padding: 0;
        max-height: none;
    }
    .navbar ul {
        float: none;
        display: block;
        margin: 0pt;
    }
    .navbar li {
        float: none;
        display: block;
    }
    .navbar li a {
        padding-top: 12px;
        padding-bottom: 12px;
        line-height: 1.1em;
    }

    .navbar li.page-nav-level-1 {
        > a {
            padding-top: 0px;
        }
        font-style: italic;
        font-size: 0.85em;
    }

}
@media(min-width: $grid-float-breakpoint) {
    .site-home-page .sidebar-nav .navbar .navbar-header {
        //display: none;
        // ### not hidden at all.
        //visibility: hidden; // hidden, but occupy the space
    }
    .sidebar-nav .navbar .navbar-header .navbar-brand {
        margin-bottom: 0.25em;
    }
}
@media(max-width: $grid-float-breakpoint) {
    .sidebar-nav .navbar {
        .navbar-header {
            margin-bottom: 0pt;
        }
        ul {
            margin-top: 12px;
        }
        li {
        }
    }
}

/* // the nav-bar doubles as page title display */
/* @media(max-width: $grid-float-breakpoint) { */
/*     .site-page-title { */
/*         display: none; */
/*     } */
/* } */

// style of side navigation bar when not grid-collapsed
@media(min-width: $grid-float-breakpoint) {
    .sidebar-nav nav {
        margin-top: 1em;
        border: none 0px transparent;
        background-color: transparent;
        text-align: right;
        padding-right: 5%;

    }

    .navbar-default .navbar-nav {
        > .active {
            > a, > a:hover, > a:focus {
                background-color: transparent;
                text-decoration: underline dotted black; 
            }
        }
        > .page-nav-level-0.active {
            > a, > a:hover, > a:focus {
                &::before {
                    content: '\0025A0';
                    padding-right: 0.5em;
                    font-family: $blacksquare-font-family;
                    line-height: 1px;
                    font-size: 75%;
                    vertical-align: 0.12em;//8%;
                }
                background-color: transparent;
                text-decoration: none;
                /*text-shadow: 3px 2px .3em rgba(0, 128, 64, .5);*/
            }
        }
    }
}







.site-content-wrapper {
    padding-bottom: 2em;
    padding-right: 5%; // by default, will be overridden 
    padding-top: 0pt;
}
@media(max-width: $screen-md-min) {
    // small screens
    .site-content-wrapper {
        padding-right: $phf-margin-page-side;
    }
    .site-content-wrapper.site-content-wrapper-notitle {
        padding-top: $phf-margin-page-side;
    }
}
@media(min-width: $screen-md-min) {
    // large screens
    .site-content-wrapper.site-content-wrapper-notitle {
        padding-top: 1em;
    }
    .site-content-wrapper.site-wide-content {    
        padding-right: 5%;
    }
    .site-content-wrapper.site-narrow-content {    
        padding-right: 25%;
    }
}



// some type/typography settings within content:

.site-content-wrapper {

    p, ul, ol, /*h1,*/ h2, h3, h4, h5, h6 {
        margin-bottom: 1em;
    }
    li {
        margin-bottom: 0.5em;
    }

    ul {
        list-style:none;

        padding-left: 2em;

        > li {
            &::before {
                float: left;
                position: relative;
                top: .75em;
                content: "\25B6";
                font-size: 0.5em;
                width: 2em;
                margin-left: -2em;
                display: inline-block;
            }
        }
    }
}



// home page stuff

@media(max-width: $grid-float-breakpoint) {
    .phf-hidden-if-navbar-collapsed {
        display: none;
    }
}

$phf-image-width: 320px;
.phf-main-image {
    width: $phf-image-width;
}
// 15px: leave some leeway because there is added 5% inner margin in the .site-content-wrapper ...
@media(max-width: 15px+$phf-image-width+2*$phf-margin-page-side) {
    .phf-main-image {
        width: 100%;
    }
}



// settings for electronic mail address obfuskating


.dyn-electr-addr, .dyn-electr-addr-set, .dyn-electr-addr-full {
    cursor: pointer;
}
.dyn-electr-addr-aasymb::before {
    content: '\0040';
}

.dyn-electr-addr-blsq {
    font-family: $blacksquare-font-family;
    font-size: 0.8em;
    padding-left: 1px;
    padding-right: 1px;
    letter-spacing: 1px;
    vertical-align: 0.07em;
    line-height: 1px;
}



.site-img-link {
    img {
        opacity: 0.9;
    }
}
.site-img-link:hover {
    img {
        opacity: 1.0;
        box-shadow: 3px 2px 2px rgba(0,0,0,0.3);
    }
}





.bib-list > li {
    font-size: 0.95*$font-size-base;
    line-height: 0.95*$line-height-computed;// 1.32em;

    padding-left: 0.5em;
}
.bib-entry {
    margin-bottom: 1em !important;
}
.bib-entry-line {
    display: block;
    margin-bottom: 0.6em;
}
.bib-entry-annotation {
    display: block;
    font-style: italic;
}
.bib-etal { font-style: italic; }
.bib-journal {
    /*font-style: italic;*/
    padding-right: 0.15em;
}
.bib-sep {
  padding-right: 0.15em;
}
.bib-title::before {
  content: '\00201c'; /* left double quote */
  margin-left: -0.2em;
}
.bib-title::after {
  content: '.\00201d'; /* right double quote */
}
.bib-title + .bib-sep { display: none; }
.bib-title.bib-x-has-punct::after {
  content: '\00201d';
}
.bib-number { /*font-weight: 600;*/ }
.bib-booktitle::before {
  content: '\00201c'; /* left double quote */
}
.bib-booktitle::after {
  content: '\00201d'; /* right double quote */
}
.bib-publisher, .bib-address {
  /*font-style: italic;*/
}
/*.bib-address::before { content: '('; }
.bib-address::after { content: ')'; }*/
.bib-publisher.bib-x-with-address::after { content: ','; }
.bib-x-pre-sep-year + .bib-sep { display: none; }
/*.bib-year::before { content: '('; }
.bib-year::after { content: ')'; }*/




@media print {

    @page {
        size: auto;
        margin: 1in 1in 1in 1in;
    }

    body {
        font-size: 14pt;
        margin: 0pt;
    }

    a[href]:after {
        display: none;
    }

    // specifics for bibliography:

    .bib-list .bib-number {
        font-weight: inherit; // EB-Garamond doesn't have any native bold face and it looks ugly in the PDF
    }
}
