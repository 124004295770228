@font-face {
  font-family: Crimson;
  font-style: normal;
  font-weight: 400;
  src: url("Crimson-Roman.54e47f15.otf") format("opentype"), url("Crimson-Roman.e81ccac7.ttf") format("truetype");
}

@font-face {
  font-family: Crimson;
  font-style: italic;
  font-weight: 400;
  src: url("Crimson-Italic.b194f863.otf") format("opentype"), url("Crimson-Italic.932e774d.ttf") format("truetype");
}

@font-face {
  font-family: Crimson;
  font-style: normal;
  font-weight: 600;
  src: url("Crimson-Semibold.b84660e6.otf") format("opentype"), url("Crimson-Semibold.25aea5e9.ttf") format("truetype");
}

@font-face {
  font-family: Crimson;
  font-style: italic;
  font-weight: 600;
  src: url("Crimson-SemiboldItalic.ce1036da.otf") format("opentype"), url("Crimson-SemiboldItalic.c2779469.ttf") format("truetype");
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: #0000;
  font-size: 10px;
}

body {
  color: #000;
  background-color: #fff;
  font-family: Crimson, serif;
  font-size: 16pt;
  line-height: 1.42857;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #2d46c0;
  text-decoration: none;
}

a:hover, a:focus {
  color: #1e2f82;
  text-decoration: none;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  -o-transition: all .2s ease-in-out;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  line-height: 1.42857;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.img-circle {
  border-radius: 50%;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin-top: 22pt;
  margin-bottom: 22pt;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

[role="button"] {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

h1 small, h1 .small, h2 small, h2 .small, h3 small, h3 .small, h4 small, h4 .small, h5 small, h5 .small, h6 small, h6 .small, .h1 small, .h1 .small, .h2 small, .h2 .small, .h3 small, .h3 .small, .h4 small, .h4 .small, .h5 small, .h5 .small, .h6 small, .h6 .small {
  color: #777;
  font-weight: normal;
  line-height: 1;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 22pt;
  margin-bottom: 11pt;
}

h1 small, h1 .small, .h1 small, .h1 .small, h2 small, h2 .small, .h2 small, .h2 .small, h3 small, h3 .small, .h3 small, .h3 .small {
  font-size: 65%;
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 11pt;
  margin-bottom: 11pt;
}

h4 small, h4 .small, .h4 small, .h4 .small, h5 small, h5 .small, .h5 small, .h5 .small, h6 small, h6 .small, .h6 small, .h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 41pt;
}

h2, .h2 {
  font-size: 25pt;
}

h3, .h3 {
  font-size: 18pt;
}

h4, .h4, h5, .h5 {
  font-size: 16pt;
}

h6, .h6 {
  font-size: 14pt;
}

p {
  margin: 0 0 11pt;
}

.lead {
  margin-bottom: 22pt;
  font-size: 18pt;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 24pt;
  }
}

small, .small {
  font-size: 87%;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase, .initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #2d46c0;
}

a.text-primary:hover, a.text-primary:focus {
  color: #233797;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover, a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover, a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover, a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover, a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #2d46c0;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #233797;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  border-bottom: 1px solid #eee;
  margin: 44pt 0 22pt;
  padding-bottom: 10pt;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 11pt;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.list-inline > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

dl {
  margin-top: 0;
  margin-bottom: 22pt;
}

dt, dd {
  line-height: 1.42857;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table;
}

.dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 992px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.initialism {
  font-size: 90%;
}

blockquote {
  border-left: 5px solid #eee;
  margin: 0 0 22pt;
  padding: 11pt 22pt;
  font-size: 20pt;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer, blockquote small, blockquote .small {
  color: #777;
  font-size: 80%;
  line-height: 1.42857;
  display: block;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: "— ";
}

.blockquote-reverse, blockquote.pull-right {
  text-align: right;
  border-left: 0;
  border-right: 5px solid #eee;
  padding-left: 0;
  padding-right: 15px;
}

.blockquote-reverse footer:before, .blockquote-reverse small:before, .blockquote-reverse .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before, blockquote.pull-right .small:before {
  content: "";
}

.blockquote-reverse footer:after, .blockquote-reverse small:after, .blockquote-reverse .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after, blockquote.pull-right .small:after {
  content: " —";
}

address {
  margin-bottom: 22pt;
  font-style: normal;
  line-height: 1.42857;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.6667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333%;
}

.col-xs-5 {
  width: 41.6667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333%;
}

.col-xs-8 {
  width: 66.6667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333%;
}

.col-xs-11 {
  width: 91.6667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.6667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333%;
}

.col-xs-pull-5 {
  right: 41.6667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333%;
}

.col-xs-pull-8 {
  right: 66.6667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333%;
}

.col-xs-pull-11 {
  right: 91.6667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.6667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333%;
}

.col-xs-push-5 {
  left: 41.6667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333%;
}

.col-xs-push-8 {
  left: 66.6667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333%;
}

.col-xs-push-11 {
  left: 91.6667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.6667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.3333%;
  }

  .col-sm-pull-5 {
    right: 41.6667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.3333%;
  }

  .col-sm-pull-8 {
    right: 66.6667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.3333%;
  }

  .col-sm-pull-11 {
    right: 91.6667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.6667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.3333%;
  }

  .col-sm-push-5 {
    left: 41.6667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.3333%;
  }

  .col-sm-push-8 {
    left: 66.6667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.3333%;
  }

  .col-sm-push-11 {
    left: 91.6667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.6667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.3333%;
  }

  .col-md-pull-5 {
    right: 41.6667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.3333%;
  }

  .col-md-pull-8 {
    right: 66.6667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.3333%;
  }

  .col-md-pull-11 {
    right: 91.6667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.6667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-5 {
    left: 41.6667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.3333%;
  }

  .col-md-push-8 {
    left: 66.6667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.3333%;
  }

  .col-md-push-11 {
    left: 91.6667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.6667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.3333%;
  }

  .col-lg-pull-5 {
    right: 41.6667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.3333%;
  }

  .col-lg-pull-8 {
    right: 66.6667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.3333%;
  }

  .col-lg-pull-11 {
    right: 91.6667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.6667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.3333%;
  }

  .col-lg-push-5 {
    left: 41.6667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.3333%;
  }

  .col-lg-push-8 {
    left: 66.6667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.3333%;
  }

  .col-lg-push-11 {
    left: 91.6667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

table {
  background-color: #0000;
}

caption {
  color: #777;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 22pt;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered, .table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  float: none;
  display: table-column;
  position: static;
}

table td[class*="col-"], table th[class*="col-"] {
  float: none;
  display: table-cell;
  position: static;
}

.table > thead > tr > td.active, .table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success, .table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info, .table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning, .table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger, .table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    margin-bottom: 16.5pt;
    overflow-y: hidden;
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  font-size: 24pt;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 22pt;
  padding: 0;
  display: block;
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  display: inline-block;
}

input[type="search"] {
  box-sizing: border-box;
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  width: 100%;
  display: block;
}

select[multiple], select[size] {
  height: auto;
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  color: #555;
  padding-top: 7px;
  font-size: 16pt;
  line-height: 1.42857;
  display: block;
}

.form-control {
  width: 100%;
  height: 33pt;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 16pt;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #00000013;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  opacity: 1;
  background-color: #eee;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    line-height: 33pt;
  }

  input[type="date"].input-sm, .input-group-sm input[type="date"], input[type="time"].input-sm, .input-group-sm input[type="time"], input[type="datetime-local"].input-sm, .input-group-sm input[type="datetime-local"], input[type="month"].input-sm, .input-group-sm input[type="month"] {
    line-height: 30.5pt;
  }

  input[type="date"].input-lg, .input-group-lg input[type="date"], input[type="time"].input-lg, .input-group-lg input[type="time"], input[type="datetime-local"].input-lg, .input-group-lg input[type="datetime-local"], input[type="month"].input-lg, .input-group-lg input[type="month"] {
    line-height: 44pt;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio, .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.radio label, .checkbox label {
  min-height: 22pt;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: normal;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  margin-left: -20px;
  margin-top: 4px \9 ;
  position: absolute;
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline, .checkbox-inline {
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: normal;
  display: inline-block;
  position: relative;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"], input[type="checkbox"][disabled], input[type="checkbox"].disabled, fieldset[disabled] input[type="checkbox"], .radio-inline.disabled, fieldset[disabled] .radio-inline, .checkbox-inline.disabled, fieldset[disabled] .checkbox-inline, .radio.disabled label, fieldset[disabled] .radio label, .checkbox.disabled label, fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  min-height: 38pt;
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

.form-control-static.input-lg, .form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm {
  height: 30.5pt;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 14pt;
  line-height: 1.5;
}

select.input-sm {
  height: 30.5pt;
  line-height: 30.5pt;
}

textarea.input-sm, select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 30.5pt;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 14pt;
  line-height: 1.5;
}

.form-group-sm select.form-control {
  height: 30.5pt;
  line-height: 30.5pt;
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30.5pt;
  min-height: 36pt;
  padding: 6px 10px;
  font-size: 14pt;
  line-height: 1.5;
}

.input-lg {
  height: 44pt;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 20pt;
  line-height: 1.33333;
}

select.input-lg {
  height: 44pt;
  line-height: 44pt;
}

textarea.input-lg, select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 44pt;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 20pt;
  line-height: 1.33333;
}

.form-group-lg select.form-control {
  height: 44pt;
  line-height: 44pt;
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 44pt;
  min-height: 42pt;
  padding: 11px 16px;
  font-size: 20pt;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 41.25pt;
}

.form-control-feedback {
  z-index: 2;
  width: 33pt;
  height: 33pt;
  text-align: center;
  pointer-events: none;
  line-height: 33pt;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.input-lg + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 44pt;
  height: 44pt;
  line-height: 44pt;
}

.input-sm + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 30.5pt;
  height: 30.5pt;
  line-height: 30.5pt;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px #00000013;
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 27pt;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  color: #404040;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .form-inline .radio, .form-inline .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .radio label, .form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
  min-height: 27.25pt;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 20pt;
  }

  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 14pt;
  }
}

.btn {
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 16pt;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px #00000020;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity= 65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover, .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  background-image: none;
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #2d46c0;
  border-color: #283eab;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #233797;
  border-color: #101944;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #233797;
  border-color: #1d2c7a;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #1d2c7a;
  border-color: #101944;
}

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
  background-color: #2d46c0;
  border-color: #283eab;
}

.btn-primary .badge {
  color: #2d46c0;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  background-image: none;
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover, .btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  background-image: none;
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover, .btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  background-image: none;
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  background-image: none;
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  color: #2d46c0;
  border-radius: 0;
  font-weight: normal;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: #0000;
}

.btn-link:hover, .btn-link:focus {
  color: #1e2f82;
  background-color: #0000;
  text-decoration: none;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}

.btn-lg {
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 20pt;
  line-height: 1.33333;
}

.btn-sm {
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 14pt;
  line-height: 1.5;
}

.btn-xs {
  border-radius: 3px;
  padding: 1px 5px;
  font-size: 14pt;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
  position: relative;
  overflow: hidden;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav:before, .nav:after {
  content: " ";
  display: table;
}

.nav:after {
  clear: both;
}

.nav > li {
  display: block;
  position: relative;
}

.nav > li > a {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: #eee;
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #0000;
  text-decoration: none;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #eee;
  border-color: #2d46c0;
}

.nav .nav-divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 10pt 0;
  overflow: hidden;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #0000;
}

.nav-pills > li {
  float: left;
}

.nav-pills > li > a {
  border-radius: 4px;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #2d46c0;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
  width: 100%;
}

.nav-justified > li, .nav-tabs.nav-justified > li {
  float: none;
}

.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified > li, .nav-tabs.nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0;
}

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
  border-radius: 4px;
  margin-right: 0;
}

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }

  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.navbar {
  min-height: 50px;
  border: 1px solid #0000;
  margin-bottom: 22pt;
  position: relative;
}

.navbar:before, .navbar:after {
  content: " ";
  display: table;
}

.navbar:after {
  clear: both;
}

@media (min-width: 992px) {
  .navbar {
    border-radius: 4px;
  }
}

.navbar-header:before, .navbar-header:after {
  content: " ";
  display: table;
}

.navbar-header:after {
  clear: both;
}

@media (min-width: 992px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #0000;
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: visible;
  box-shadow: inset 0 1px #ffffff1a;
}

.navbar-collapse:before, .navbar-collapse:after {
  content: " ";
  display: table;
}

.navbar-collapse:after {
  clear: both;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 992px) {
  .navbar-collapse {
    width: auto;
    box-shadow: none;
    border-top: 0;
  }

  .navbar-collapse.collapse {
    padding-bottom: 0;
    height: auto !important;
    display: block !important;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

.container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 992px) {
  .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 992px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 1030;
  position: fixed;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  border-width: 0 0 1px;
  top: 0;
}

.navbar-fixed-bottom {
  border-width: 1px 0 0;
  margin-bottom: 0;
  bottom: 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 10.3333px 15px;
  font-size: 20pt;
  line-height: 22pt;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 992px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.navbar-toggle {
  float: right;
  background-color: #0000;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 15px;
  padding: 9px 10px;
  position: relative;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  width: 22px;
  height: 2px;
  border-radius: 1px;
  display: block;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 992px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 5.16667px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 22pt;
}

@media (max-width: 991px) {
  .navbar-nav .open .dropdown-menu {
    float: none;
    width: auto;
    box-shadow: none;
    background-color: #0000;
    border: 0;
    margin-top: 0;
    position: static;
  }

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 22pt;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 10.3333px;
    padding-bottom: 10.3333px;
  }
}

.navbar-form {
  border-top: 1px solid #0000;
  border-bottom: 1px solid #0000;
  margin: 3px -15px;
  padding: 10px 15px;
  box-shadow: inset 0 1px #ffffff1a, 0 1px #ffffff1a;
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .navbar-form .form-control-static {
    display: inline-block;
  }

  .navbar-form .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
    width: auto;
  }

  .navbar-form .input-group > .form-control {
    width: 100%;
  }

  .navbar-form .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .navbar-form .radio, .navbar-form .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .radio label, .navbar-form .checkbox label {
    padding-left: 0;
  }

  .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 991px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .navbar-form {
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-radius: 4px 4px 0 0;
  margin-bottom: 0;
}

.navbar-btn {
  margin-top: 3px;
  margin-bottom: 3px;
}

.navbar-btn.btn-sm {
  margin-top: 4.66667px;
  margin-bottom: 4.66667px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 10.3333px;
  margin-bottom: 10.3333px;
}

@media (min-width: 992px) {
  .navbar-text {
    float: left;
    margin-left: 15px;
    margin-right: 15px;
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    margin-right: -15px;
    float: right !important;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #33333a;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #1b1b1f;
  background-color: #0000;
}

.navbar-default .navbar-text {
  color: #777;
}

.navbar-default .navbar-nav > li > a {
  color: #33333a;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #222228;
  background-color: #0000;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #33333a;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: #0000;
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #33333a;
  background-color: #e7e7e7;
}

@media (max-width: 991px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #33333a;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222228;
    background-color: #0000;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #33333a;
    background-color: #e7e7e7;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: #0000;
  }
}

.navbar-default .navbar-link {
  color: #33333a;
}

.navbar-default .navbar-link:hover {
  color: #222228;
}

.navbar-default .btn-link {
  color: #33333a;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #222228;
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:hover, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #090909;
}

.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #090909;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: #0000;
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #090909;
}

@media (max-width: 991px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: #0000;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: #0000;
  }
}

.navbar-inverse .navbar-link {
  color: #9d9d9d;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #9d9d9d;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:hover, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 6px;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 9px #00000080;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity= 0);
}

.modal-backdrop.in {
  opacity: .5;
  filter: alpha(opacity= 50);
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-header:before, .modal-header:after {
  content: " ";
  display: table;
}

.modal-header:after {
  clear: both;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857;
}

.modal-body {
  padding: 15px;
  position: relative;
}

.modal-footer {
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px #00000080;
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md, td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.padding-0 {
  padding: 0;
}

html, body {
  height: 100%;
}

.type-lnum {
  -moz-font-feature-settings: "lnum=1";
  -moz-font-feature-settings: "lnum";
  -ms-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}

.type-swash {
  -moz-font-feature-settings: "swsh=1,cswh=1";
  -moz-font-feature-settings: "swsh", "cswh";
  -ms-font-feature-settings: "swsh", "cswh";
  -webkit-font-feature-settings: "swsh", "cswh";
  font-feature-settings: "swsh", "cswh";
}

p, li, h1, h2, h3, h4, h5, h6 {
  -moz-font-feature-settings: "calt=1, liga=1, clig=1, kern=1, onum=1";
  -moz-font-feature-settings: "calt", "liga", "clig", "kern", "onum";
  -ms-font-feature-settings: "calt", "liga", "clig", "kern", "onum";
  -webkit-font-feature-settings: "calt", "liga", "clig", "kern", "onum";
  font-feature-settings: "calt", "liga", "clig", "kern", "onum";
  font-variant-ligatures: common-ligatures discretionary-ligatures contextual;
  font-variant-numeric: oldstyle-nums;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  -moz-font-feature-settings: "swsh=1,cswh=1";
  -moz-font-feature-settings: "swsh", "cswh";
  -ms-font-feature-settings: "swsh", "cswh";
  -webkit-font-feature-settings: "swsh", "cswh";
  font-feature-settings: "swsh", "cswh";
}

h3 {
  font-style: italic;
}

@media (max-width: 992px) {
  h1, .h1 {
    font-size: 32.8pt;
  }

  h2, .h2 {
    font-size: 20pt;
  }

  h3, .h3 {
    font-size: 14.4pt;
  }

  h4, .h4, h5, .h5 {
    font-size: 12.8pt;
  }

  h6, .h6 {
    font-size: 11.2pt;
  }
}

blockquote {
  font-size: inherit;
  border: 0;
  padding: .5em 2em;
}

@media (max-width: 20em) {
  blockquote {
    padding-left: 4%;
    padding-right: 4%;
  }
}

a {
  text-shadow: 3px 2px .3em #0000001a;
}

a:hover {
  text-shadow: 3px 2px .3em #0003;
}

.sidebar-nav a:hover {
  text-shadow: 3px 2px .3em #2222284d;
}

.sidebar-nav {
  padding: 0;
}

.sidebar-nav .navbar {
  margin-bottom: 0;
}

.sidebar-nav .navbar-header {
  float: none;
  display: block;
}

.sidebar-nav .navbar-brand {
  float: none;
  height: auto;
  display: block;
}

.sidebar-nav .navbar .navbar-collapse {
  max-height: none;
  padding: 0;
}

.sidebar-nav .navbar ul {
  float: none;
  margin: 0;
  display: block;
}

.sidebar-nav .navbar li {
  float: none;
  display: block;
}

.sidebar-nav .navbar li a {
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.1em;
}

.sidebar-nav .navbar li.page-nav-level-1 {
  font-size: .85em;
  font-style: italic;
}

.sidebar-nav .navbar li.page-nav-level-1 > a {
  padding-top: 0;
}

@media (min-width: 992px) {
  .sidebar-nav .navbar .navbar-header .navbar-brand {
    margin-bottom: .25em;
  }
}

@media (max-width: 992px) {
  .sidebar-nav .navbar .navbar-header {
    margin-bottom: 0;
  }

  .sidebar-nav .navbar ul {
    margin-top: 12px;
  }
}

@media (min-width: 992px) {
  .sidebar-nav nav {
    text-align: right;
    background-color: #0000;
    border: 0 #0000;
    margin-top: 1em;
    padding-right: 5%;
  }

  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    background-color: #0000;
    text-decoration: underline dotted #000;
  }

  .navbar-default .navbar-nav > .page-nav-level-0.active > a, .navbar-default .navbar-nav > .page-nav-level-0.active > a:hover, .navbar-default .navbar-nav > .page-nav-level-0.active > a:focus {
    background-color: #0000;
    text-decoration: none;
  }

  .navbar-default .navbar-nav > .page-nav-level-0.active > a:before, .navbar-default .navbar-nav > .page-nav-level-0.active > a:hover:before, .navbar-default .navbar-nav > .page-nav-level-0.active > a:focus:before {
    content: "■";
    vertical-align: .12em;
    padding-right: .5em;
    font-family: Times New Roman, serif;
    font-size: 75%;
    line-height: 1px;
  }
}

.site-content-wrapper {
  padding-top: 0;
  padding-bottom: 2em;
  padding-right: 5%;
}

@media (max-width: 992px) {
  .site-content-wrapper {
    padding-right: 15px;
  }

  .site-content-wrapper.site-content-wrapper-notitle {
    padding-top: 15px;
  }
}

@media (min-width: 992px) {
  .site-content-wrapper.site-content-wrapper-notitle {
    padding-top: 1em;
  }

  .site-content-wrapper.site-wide-content {
    padding-right: 5%;
  }

  .site-content-wrapper.site-narrow-content {
    padding-right: 25%;
  }
}

.site-content-wrapper p, .site-content-wrapper ul, .site-content-wrapper ol, .site-content-wrapper h2, .site-content-wrapper h3, .site-content-wrapper h4, .site-content-wrapper h5, .site-content-wrapper h6 {
  margin-bottom: 1em;
}

.site-content-wrapper li {
  margin-bottom: .5em;
}

.site-content-wrapper ul {
  padding-left: 2em;
  list-style: none;
}

.site-content-wrapper ul > li:before {
  float: left;
  content: "▶";
  width: 2em;
  margin-left: -2em;
  font-size: .5em;
  display: inline-block;
  position: relative;
  top: .75em;
}

@media (max-width: 992px) {
  .phf-hidden-if-navbar-collapsed {
    display: none;
  }
}

.phf-main-image {
  width: 320px;
}

@media (max-width: 365px) {
  .phf-main-image {
    width: 100%;
  }
}

.dyn-electr-addr, .dyn-electr-addr-set, .dyn-electr-addr-full {
  cursor: pointer;
}

.dyn-electr-addr-aasymb:before {
  content: "@";
}

.dyn-electr-addr-blsq {
  letter-spacing: 1px;
  vertical-align: .07em;
  padding-left: 1px;
  padding-right: 1px;
  font-family: Times New Roman, serif;
  font-size: .8em;
  line-height: 1px;
}

.site-img-link img {
  opacity: .9;
}

.site-img-link:hover img {
  opacity: 1;
  box-shadow: 3px 2px 2px #0000004d;
}

.bib-list > li {
  padding-left: .5em;
  font-size: 15.2pt;
  line-height: 20.9pt;
}

.bib-entry {
  margin-bottom: 1em !important;
}

.bib-entry-line {
  margin-bottom: .6em;
  display: block;
}

.bib-entry-annotation {
  font-style: italic;
  display: block;
}

.bib-etal {
  font-style: italic;
}

.bib-journal, .bib-sep {
  padding-right: .15em;
}

.bib-title:before {
  content: "“";
  margin-left: -.2em;
}

.bib-title:after {
  content: ".”";
}

.bib-title + .bib-sep {
  display: none;
}

.bib-title.bib-x-has-punct:after {
  content: "”";
}

.bib-booktitle:before {
  content: "“";
}

.bib-booktitle:after {
  content: "”";
}

.bib-publisher.bib-x-with-address:after {
  content: ",";
}

.bib-x-pre-sep-year + .bib-sep {
  display: none;
}

@media print {
  @page {
    size: auto;
    margin: 1in;
  }

  body {
    margin: 0;
    font-size: 14pt;
  }

  a[href]:after {
    display: none;
  }

  .bib-list .bib-number {
    font-weight: inherit;
  }
}

/*# sourceMappingURL=index.b14b1e04.css.map */
