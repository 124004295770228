
@font-face {
    font-family: 'Crimson';
    font-style: normal;
    font-weight: 400;
    src: url("~/fonts/Crimson-Roman.otf") format('opentype'), url("~/fonts/Crimson-Roman.ttf") format('truetype');
}

@font-face {
    font-family: 'Crimson';
    font-style: italic;
    font-weight: 400;
    src: url("~/fonts/Crimson-Italic.otf") format('opentype'), url("~/fonts/Crimson-Italic.ttf") format('truetype');
}

@font-face {
    font-family: 'Crimson';
    font-style: normal;
    font-weight: 600;
    src: url("~/fonts/Crimson-Semibold.otf") format('opentype'), url("~/fonts/Crimson-Semibold.ttf") format('truetype');
}

@font-face {
    font-family: 'Crimson';
    font-style: italic;
    font-weight: 600;
    src: url("~/fonts/Crimson-SemiboldItalic.otf") format('opentype'), url("~/fonts/Crimson-SemiboldItalic.ttf") format('truetype');
}

